@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.artistWrap {
  margin: 0 auto !important;
  padding: 0;
  max-width: 100%;

  @include media-breakpoint-up(md) {
    max-width: 992px !important;
  }

  @include media-breakpoint-up(lg) {
    max-width: 1200px !important;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1200px !important;
  }
}

.carouselWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.artistWrap {
  @import "../../App.scss";
  @import "./Carousel.module.scss";
  position: relative;

  display: flex;
  flex-direction: column;

  .goBack {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    justify-content: flex-start;
    z-index: 1111111;
    padding-bottom: 2rem;
    width: 1rem;
    height: 1rem;
    & svg {
      background-color: transparent;
      width: fit-content;
      padding: 0;
      filter: invert(0);
      transform: rotate(180deg);
    }
    @include media-breakpoint-down(sm) {
      right: unset;
      left: 1rem;
    }
  }
  .innerWrap {
    z-index: 100;

    .artistContent {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
      padding: 4rem 2rem;
      .left {
        display: flex;
        width: 40%;
        .content {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;
          h2 {
          }
          p {
          }
          a {
          }
        }
      }
      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        & h2 {
          display: none;
        }
        .imgWrap {
          width: 350px;
          height: 350px;
          & * {
            width: 100%;
            height: 100%;
          }
          & img {
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
    .portfolioWrap {
      width: 100%;
      padding-bottom: 2rem;

      .imgWrap {
        height: 400px;
        width: 100%;
        padding: 1rem;

        & > span {
          width: 100%;
          height: 100%;
        }

        & img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  //
  // Media Queries
  //

  @include media-breakpoint-down(md) {
    .innerWrap {
      .artistContent {
        flex-wrap: wrap;
        padding: 2rem 1rem;
        .left {
          width: 100%;
          order: 2;
          min-width: 300px;
          .content {
            h2 {
              display: none;
            }
            p {
              max-width: 500px;
            }
            a {
            }
          }
        }
        .right {
          width: 100%;
          min-width: 300px;
          flex-direction: column;
          & h2 {
            display: inherit;
            margin-bottom: 1rem;
          }
          .imgWrap {
            width: 100%;
            margin-bottom: 2rem;
          }
        }
      }
      .portfolioWrap {
        width: 100%;

        .imgWrap {
        }
      }
    }
  }
}
