@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.modalWrapper {
  min-height: calc(100vh - 231px);
  padding-top: 100px;
  background-color: transparent;
  flex-direction: column;
  z-index: 100;

  display: flex;
  align-items: center;

  .overlay {
    width: 100%;
    &::before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      background: url("../../images/overlay6.png") no-repeat;
      opacity: 0.05;
      width: 100%;
      height: 100%;
      background-size: cover;
      z-index: 0;
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    z-index: 100;

    .goBack {
      display: flex;
      padding-top: 1.5rem;
      padding-left: 1rem;
      cursor: pointer;

      & svg {
        transform: translateX(0.3rem);
        transition: transform 0.3s ease-in;
      }

      & p {
        padding-left: 0.6rem;
      }

      &:hover {
        svg {
          transform: translateX(0);
        }
      }
    }

    width: 100%;
    height: 100%;
    & * {
      color: black;
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 70px;
    .inner {
      & * {
        // font-size: 1rem;
      }
    }
  }
}
