@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.overlayBG {
  position: relative;
  z-index: 0;
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    background: url("../../../images/overlay6.png") no-repeat;
    opacity: 0.04;
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: -1;
  }
}

.homeWrapper {
  @import "../../App.scss";
  overflow: hidden;
  padding: 0;
  margin: 0;
  background-color: whitesmoke;

  .sectionWrap {
    padding: 0 2rem;
    z-index: 1;
    .wrap {
      padding: 0;

      .title {
        position: absolute;
        top: 0;
        left: -4rem;
        color: whitesmoke;
        transform: rotate(90deg) translateX(40%) translateY(-50%);
        width: fit-content;
        z-index: 2;
        font-size: 1.25rem;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: -2.4rem;
          height: 1px;
          width: 2rem;
          background-color: whitesmoke;
          transform: translateY(-50%);
        }
        @include media-breakpoint-down(md) {
          transform: none;
          left: 0;
          font-size: 1rem;
          top: 0rem;
          top: -2rem;
          display: none;
        }
      }
    }
    .spacer {
      height: 125px;
    }

    @include media-breakpoint-down(md) {
      .spacer {
        height: 75px;
      }
    }
    @include media-breakpoint-down(sm) {
      padding: 0 1rem;
      .spacer {
        height: 50px;
      }
    }
  }

  .heroBG {
    // background: url("../../../images/hero.png") no-repeat;
    // background-position: center right;
    // background-size: contain;
    background-color: white;
    position: relative;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    padding-left: 3.5rem;
    z-index: 1;
    @include media-breakpoint-down(lg) {
      padding-left: 4rem;
    }
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../../../images/hero.png") no-repeat;
      background-position: center right;
      background-size: contain;
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 0) 90%
      );
      z-index: -1;
    }
    .heroRow {
      position: relative;
      min-height: 100vh;
      align-items: center;
      z-index: 2;
      .heroWrap {
        .contentWrap {
          z-index: 2;
          max-width: 370px;
          & h1 {
            line-height: 0.8;
            color: black;
            & span {
              font-weight: bold;
              letter-spacing: 2px;
            }
          }
          & p {
            margin: 1.5rem 0;
            color: black;

            &:first-of-type {
              max-width: 320px;
            }
          }
        }
        .buttonWrap {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 2.5rem;
          width: fit-content;
          z-index: 2;
        }
      }
      .socialWrap {
        position: absolute;
        bottom: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        z-index: 2;
        .innerWrap {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1.5rem;
          & svg {
            font-size: 1.5rem;
            width: 2rem;
            height: 2rem;
            padding: 0.3rem;
            border-radius: 50%;
          }
          a {
            color: black;
            text-decoration: none;
          }
        }
      }
    }
  }

  .aboutWrap {
    position: relative;
    min-height: 130px;
    & * {
      color: black;
    }
    .wrap {
      display: flex;
      .left {
        flex: 1;
      }
      .right {
        flex: 2;
        .content {
          display: flex;
          flex-direction: column;
          gap: 2rem;
        }
      }
    }
  }

  .artistWrap {
    & * {
      color: black;
    }
    & h4 {
      letter-spacing: 2px;
    }
    .wrap {
      .cardsWrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
        .card {
          flex: 1;
          // max-width: 33%;
          min-width: 300px;
          background: none;
          border: none;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .img {
            max-height: 360px;
            min-height: 200px;
            width: 100%;
            height: 100%;
            & * {
              width: 100%;
              height: 100%;
            }
            & img {
              object-fit: cover;
            }
          }
          .content {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            & p {
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            & a {
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
  }

  .piercingWrap {
    & * {
      color: black;
    }
    .wrap {
      display: flex;
      flex-wrap: nowrap;
      gap: 2rem;
      .left {
        flex: 1;
        width: 100%;
        min-width: 300px;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        h2 {
          line-height: 0.8;
        }
        p {
          max-width: 450px;
        }
      }
      .right {
        flex: 1;
        min-width: 250px;
        width: 100%;
        & h2 {
          display: none;
        }
        .imgWrap {
          max-height: 360px;
          width: 100%;
          height: 100%;
          & * {
            width: 100%;
            height: 100%;
          }
          & img {
            object-fit: cover;
            object-position: 50% 20%;
          }
        }
      }
    }
  }

  .studioBannerWrap {
    .wrap {
      position: relative;
      .imgWrap {
        @include media-breakpoint-up(md) {
          min-height: 500px;
        }
        width: 100%;
        height: 100%;
        & * {
          width: 100%;
          height: 100%;
        }
        & img {
          object-fit: cover;
          object-position: center;
        }
      }
      .contentWrap {
        position: absolute;
        left: 0;
        bottom: -50px;
        background-color: black;
        padding: 3rem;
        width: 400px;
        line-height: 21px;
      }
    }
  }

  .contactWrap {
    position: relative;
    & * {
      color: black;
      text-decoration: none;
    }
    .wrap {
      display: flex;
      gap: 2rem;
      .left {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        max-width: 40%;
        width: 100%;
        h2 {
          margin-bottom: 0.5rem;
        }
        p {
          max-width: 310px;
        }
        .btnPrimary {
          width: fit-content;
        }
        .social {
          display: flex;
          flex-direction: row;
          gap: 1rem;
          & a {
            font-size: 1rem;
          }
        }
      }
      .right {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        .content {
          width: fit-content;
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      }
      // .map {
      //   position: relative;
      //   max-width: 69%;
      //   width: 100%;
      // }
    }
  }

  //
  // MEDIA QUERIES -––– MD
  //
  @media (max-width: 1200px) {
    .heroBG {
      &::before {
        height: 80%;
      }
      .heroRow {
        .socialWrap {
          margin-top: 3rem;
          position: unset;
          align-items: flex-start;
          .innerWrap {
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .heroBG {
      &::before {
        height: 65%;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .heroBG {
      &::before {
        height: 50%;
      }
      .heroRow {
        .heroWrap {
          .socialWrap {
            position: absolute;
            align-items: center;
            margin: 0;
            .innerWrap {
              flex-direction: row;
              align-items: center;
            }
          }
        }
      }
    }

    .aboutWrap {
      position: relative;
      & * {
      }
      .wrap {
        flex-direction: column;
        & h2 {
          margin-bottom: 1rem;
        }
      }
    }

    .studioBannerWrap {
      margin-bottom: 100px;
      .wrap {
        .imgWrap {
          & * {
          }
          & img {
          }
        }
        .contentWrap {
          bottom: -150px;
        }
      }
    }

    .contactWrap {
      .wrap {
        flex-wrap: wrap;
        .left {
          min-width: 300px;
        }
        .right {
          min-width: 300px;
          iframe {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 680px) {
    .heroBG {
      &::before {
        height: 80%;
        top: unset;
        bottom: 0;
        opacity: 0.5;
      }
      .heroRow {
      }
    }
  }

  //
  // MEDIA QUERIES -––– SM
  //

  @include media-breakpoint-down(sm) {
    .heroBG {
      min-height: unset;
      height: calc(100vh - 70px);
      padding: 0;
      &::before {
        background-size: cover;
      }
      &::after {
      }

      .heroRow {
        align-items: flex-end;
        min-height: unset;
        height: calc(100vh - 70px);
        .heroWrap {
          padding-bottom: 120px;
          .contentWrap {
            & h1 {
            }
            & p {
            }
          }
          .buttonWrap {
            gap: 1rem;
            flex-wrap: nowrap;
          }
        }
        .socialWrap {
          bottom: 2rem;
        }
      }
    }

    .aboutWrap {
      position: relative;
      & * {
      }
      .wrap {
      }
    }

    .piercingWrap {
      & h2 {
        display: inherit;
      }
      .wrap {
        flex-wrap: wrap;
        .left {
          order: 2;
          h2 {
            display: none;
          }
          p {
            max-width: 450px;
          }
        }
        .right {
          h2 {
            display: inherit;
            margin-bottom: 1.5rem;
          }
        }
      }
    }

    .studioBannerWrap {
      margin-bottom: 0px;
      .wrap {
        .imgWrap {
          display: none;
          & * {
          }
          & img {
          }
        }
        .contentWrap {
          position: inherit;
          bottom: 0;
          width: auto;
          padding: 2rem 1rem;
        }
      }
    }
  }
}
