@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.footerWrap {
  @import "../../App.scss";
  overflow: hidden;
  padding: 0;
  margin: 0;
  position: relative;
  background: black;

  .sectionWrap {
    padding: 0 2rem;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .wrap {
      padding: 0;
    }
    & * {
      color: whitesmoke;
    }

    .top {
      .wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        .inner {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: center;
          gap: 1rem;

          &:first-child {
            align-items: center;
          }
          & p,
          & a {
            color: rgba(whitesmoke, 0.8);
          }
          & a {
            &:hover {
              color: rgba(whitesmoke, 1);
            }
          }
          .imgWrap {
            max-width: 150px;
            width: 100%;
            & img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }
          }
          .content {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            & a {
              text-transform: capitalize;
              text-decoration: none;
              opacity: 0.95;
              &:hover {
                text-decoration: underline;
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .bottom {
      padding-bottom: 2rem;
      .wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .inner {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          flex-wrap: wrap;
          gap: 1rem;
          & * {
            font-size: 0.7rem;
            & span {
              font-size: 1rem;
            }
            color: rgba(whitesmoke, 0.5);
          }
          a {
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  //
  // Media Queries MD
  //

  @include media-breakpoint-down(md) {
    .sectionWrap {
      .wrap {
      }
      .spacer {
      }

      & * {
      }

      .top {
        .wrap {
          justify-content: space-evenly;
          gap: 3rem;
          .inner {
            align-items: center;
            @include media-breakpoint-down(sm) {
              & * {
                text-align: center;
              }
            }
            &:first-child {
              width: 100%;
            }
            .imgWrap {
              & img {
              }
            }
            .content {
              align-items: center;
              & a {
                &:hover {
                }
              }
            }
          }
        }
      }
      .bottom {
        .wrap {
          justify-content: center;
          gap: 1rem;

          & p {
          }
          .inner {
            justify-content: center;
            text-align: center;
            & * {
              font-size: 0.7rem !important;
              & span {
                font-size: 1rem !important;
              }
            }
          }
        }
      }
    }
  }
}
