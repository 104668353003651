@import url("https://fonts.googleapis.com/css2?family=Cormorant+Unicase:wght@300;400;500;600;700&family=Cormorant:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Questrial&family=Teko:wght@300..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "~bootstrap/scss/variables";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

body {
  font-family: "Roboto", Roboto;
  font-weight: 500;
  color: whitesmoke;
  font-size: 1rem;
  line-height: 1.25;
  box-sizing: border-box;
  letter-spacing: 1.25px;
  height: 100vh;
  & > div {
    height: 100%;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Teko", Teko;
  font-weight: 300;
  color: whitesmoke;
}

h1 {
  font-size: 3rem;
  line-height: 1;
  text-transform: uppercase;
}

h2 {
  font-size: 2.75rem;
  line-height: 1;
  text-transform: uppercase;
}

h3 {
  font-size: 2.5rem;
  line-height: 1;
  text-transform: uppercase;
}

h4 {
  font-size: 2rem;
  line-height: 1;
  text-transform: uppercase;
}

h5 {
  font-size: 1.5rem;
  line-height: 1;
  text-transform: uppercase;
}

h6 {
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
}

@include media-breakpoint-down(md) {
  p {
    font-size: 1rem !important;
  }
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
}

@include media-breakpoint-down(sm) {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.75rem;
  }
  h3 {
    font-size: 1.5rem;
  }
}

.app-container {
  overflow: hidden;
}

.container-fluid {
  padding: 0;
  margin: 0 auto !important;
  overflow: hidden;
}

.container {
  margin: 0 auto !important;
  @include media-breakpoint-up(xl) {
    max-width: 990px;
  }
  @include media-breakpoint-up(xl) {
    max-width: 1200px;
  }
  max-width: 100%;
  padding: 0;
  // padding-bottom: 2rem;
}

.btnPrimary {
  background-color: transparent !important;
  border: 2px solid black !important;
  color: black !important;
  border-radius: 1px !important;
  height: 40px !important;
  padding: 0.4rem 1rem !important;
  font-size: 1rem;
  white-space: nowrap;
  text-decoration: none;

  @include media-breakpoint-up(sm) {
    padding: 0.35rem 2rem !important;
    font-size: 1.2rem;
  }
}

.btnPrimaryInverted {
  background-color: transparent !important;
  border: 2px solid black !important;
  color: black !important;
  border-radius: 1px !important;
  height: 40px !important;
  padding: 0.35rem 2rem !important;
}

.slick-arrow {
  top: unset !important;
  bottom: -3rem !important;
  left: 0 !important;
  color: transparent !important;

  &::after,
  &::before {
    font-size: 2rem;
  }
}

.slick-next {
  left: 3rem !important;
}

.testimonial-carousel {
  .slick-arrow {
    top: 65% !important;
    transform: translateY(-50%);
    left: -5rem !important;
    width: 0 !important;
    height: 0 !important;

    &::after,
    &::before {
      font-size: 3rem;
      background-color: transparent !important;
    }
  }
  .slick-next {
    right: -2rem !important;
    left: unset !important;
  }
  .slick-dots {
    bottom: -2rem;
    & * {
      &::after,
      &::before {
        color: whitesmoke !important;
        font-size: 0.75rem;
      }
    }
  }
}

/* styles.css */
.slick-dots li {
  display: none !important; /* Hide all dots by default */
}

.slick-dots li.slick-active,
.slick-dots li:nth-child(-n + 6) {
  display: inline-block !important; /* Display the active dot and the first 5 dots */
}

.progressive-img-loading {
  position: relative;
  filter: blur(5px);
  clip-path: inset(0);
}
.progressive-img-loaded {
  filter: blur(0px);
  transition: filter 0.3s linear;
}
