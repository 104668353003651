@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.left {
  & svg {
    position: absolute;
    // padding-bottom: 1rem;
    top: 1rem;
    left: 1rem;
  }
}

.termsWrap {
  @import "../../App.scss";
  position: relative;

  flex-direction: column;
  height: 100%;

  .goBack {
    position: fixed;
    top: 1rem;
    left: 1rem;
    display: flex;
    justify-content: flex-start;
    z-index: 111111;
    padding-bottom: 2rem;
    & svg {
      background-color: whitesmoke;
      width: fit-content;
      padding: 0;
    }
  }
  .innerWrap {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;

    .left {
      display: flex;
      flex-direction: column;
      padding: 2rem 1rem;
      gap: 2rem;
      overflow: hidden;
      .head {
        display: flex;
        justify-content: center;
      }
      .content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding-top: 1rem;
      }
      .head {
        text-align: center;
        padding: 2rem 0;
      }
      .itemWrap {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .title {
          font-size: 1.25rem;
          padding-bottom: 1rem;
          font-weight: 700;
          border-bottom: solid 2px rgba(black, 0.75);
        }
        .body {
          padding: 1rem;
          white-space: pre-line;
          font-weight: 300;
        }
      }
    }
    .right {
      position: relative;
      flex-direction: row;
      justify-content: space-between;
      padding: 1rem 1rem;
      padding-top: 4rem;
      display: flex;
      gap: 3rem;
      & * {
        color: black !important;
      }

      .logo {
        max-width: 200px;
        width: 100%;
        height: auto;
        z-index: 2;
        & img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0.4rem;
      }

      .socialIcons {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 0.5rem;
        & svg {
          background-color: rgba(whitesmoke, 0.9);
          border-radius: 50%;
          padding: 0.2rem;
          font-size: 1.5rem;
          & * {
            fill: black;
          }
          &:hover {
            background-color: rgba(whitesmoke, 1);
            transition: scale 0.2s ease-in-out;
            scale: 1.1;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    // .goBack {
    //   & * {
    //     fill: whitesmoke;
    //     background-color: transparent !important;
    //   }
    // }
    .innerWrap {
      flex-direction: column;
      height: unset;
      .left {
        flex: unset;
        max-width: 100%;
        order: 2;
        padding: 1rem;
        .head {
          padding: 0;
          & h2 {
            // font-size: 1.6rem;
          }
        }
        .content {
          .body {
            padding: 0;
          }
        }
      }
      .right {
        flex: unset;
        order: 1;
        max-width: 100%;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
        align-items: center;
        .logo {
          max-width: 100px;
          margin: 0;
        }
        .content {
          // display: none;
          gap: 1rem;
          & div:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
}
