.inputWrap {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1.5rem;

  .number {
    // border-radius: 15px;
    & p {
      // border-bottom: 2px solid rgba(#000000, 0.5);
    }
    padding: 0rem 0.5rem;
  }

  .input {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;

    & label {
      position: relative;
      width: fit-content;
      &::after {
        content: "*";
        color: red;
        position: absolute;
        top: 0.1rem;
        right: -0.5rem;
        font-size: 0.9rem;
      }
    }

    & input,
    & select,
    & textarea {
      border: none;
      background-color: transparent;
      border-bottom: 1px solid rgba(#000000, 0.5);
      padding-bottom: 0.25rem;
      width: 100%;
      display: block;
      height: auto !important;
    }
    & select {
      // text-transform: capitalize;
      option:disabled {
        opacity: 0.5;
        color: rgba(#000000, 0.5);
      }
    }
  }
}
